function create(data, options){
    const payload = {
        data : data,
        options : options
    }
    return window.axios.post('/api/venta/create', payload);
}

function getVentasPendientes(){
    return window.axios.get('/api/venta/pendiente');
}

function getTickets(status){
    return window.axios.get('/api/venta/tickets/'+status);
}
export default {
    create,getVentasPendientes,getTickets
}