const namespaced = true

const state = {
    user: {
        name: '',
        email: '',
        abilities: []
    },
}
const mutations = {
    MUTATION_RESET_USER(state) {
        state.user = { name: '', email: '', abilities: [] } ;
    },
    SET_ROLE_USER(state, input_data){
        state.role = input_data;
    },
    MUTATION_SET_USERAUTH(state, value) {
        state.user = value;
    },
}
const actions = {
    setUserAuth(context, data){
        context.commit('MUTATION_SET_USERAUTH', data);
    },
    resetUserAuth(context){
        context.commit('MUTATION_RESET_USER');
    }
    // getInformation(context) {
    //     return new Promise((resolve) => { 
    //         api.getInformation().then(function (res) {
    //             context.commit('MUTATION_SET_USER', res.data.data);
    //             resolve(res.data.data);
    //         }).catch(function (error) {
    //             console.log(error)
    //         })
    //     })        
    // },
}

const getters = {
    getAbilitiesUser(state){
        return state.user.abilities;
    },
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}