const namespaced = true


const state = {
    loading : false,
    message : '' ,
}
const mutations = {
    MUTATION_SET_LOADING(state, data){
         state.loading     = data;
    },
    MUTATION_SET_MESSAGE(state, data){
         state.message     = data;
    }
}
const actions = {
    setLoading(context,data) {
        context.commit('MUTATION_SET_LOADING', data);
    },
    setMessage(context, data){
        context.commit('MUTATION_SET_MESSAGE', data);
    },
    show(context, {loading,message}){
        context.commit('MUTATION_SET_LOADING', loading);
        context.commit('MUTATION_SET_MESSAGE', message);
    }

}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}