function getSocios(){
    return window.axios.get('/api/socios/index');
}
function getSocio(){
    return window.axios.get('/api/socios/socio');
}
function postSocio(data){
    return window.axios.post('/api/socios/create',data);
}

export default {
    getSocios,postSocio,getSocio
}