<template >
    <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="accent" dark>
            <v-card-text>
                {{ message }}
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() { return {} },
    computed: {
        ...mapState('progress', ['loading', 'message'])
    }
}
</script>
