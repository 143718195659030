const namespaced = true

import api from './../../api/pagos.js';

const state = {
    pago: {        
        paymentMethod : "",
        date:"",
        bankAccount:{
            id : -1
        },
        type : "",
        invoices: []         
    },
}
const mutations = {
    MUTATION_SET_PAGO(state, pago){
        state.pago     = pago;
    }, 
    MUTATION_ADD_TICKET_TO_PAGO(state, ticket){
        state.pago.invoices.push({ id: ticket.id, amount:ticket.total });
    }, 
    MUTATION_SET_PAGO_RESET(state){
        state.pago = { paymentMethod : "", date: "", bankAccount: { id : -1 }, type : "", invoices: [] }
    } 
}
const actions = {
    async create(context, ticket) {
        context.commit('MUTATION_ADD_TICKET_TO_PAGO', ticket);
        try {
            context.dispatch('progress/show', { 'loading': true, 'message': 'Creando confirmacion de deposito...' }, { root: true });
            
            const res = await api.create(context.state.pago);

            await context.commit('MUTATION_SET_PAGO_RESET');
            await context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            await context.dispatch('ventas/getVentasPendientes', { }, { root: true })
            
            return res.data;
        } catch (e) {
            context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text':  'Error al registrar la transferencia (FE)'}, { root: true });
        }

    },
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}