<template>
    <v-container v-if="isLoggedIn">
      <v-app-bar app clipped-left fixed>
        <v-row>
          <v-col cols="2" sm="2" md="2" lg="2" xl="2">
            <v-btn outlined @click.stop="drawerConf = !drawerConf">
              <span class="mdi mdi-menu"></span>
            </v-btn>
          </v-col>
          <v-col cols="10" sm="10" md="10" lg="10" xl="10">
            <span class="subtitle-2 mx-auto font-weight-bold text-truncate">{{ $route.meta.title }} </span>
          </v-col>
        </v-row>
      </v-app-bar>
  
      <v-navigation-drawer v-model="drawerConf" fixed temporary>
        <template v-slot:prepend>
          <v-list-item class="px-4 pt-1">
            <v-list-item-title>Menú</v-list-item-title>
            <v-btn icon @click.stop="drawerConf = !drawerConf">
              <v-icon color="error">mdi-close</v-icon>
            </v-btn>
          </v-list-item>
        </template>
        <v-divider></v-divider>
  
        <v-list dense nav rounded>
          <v-list-group v-for="(module, index) in modulesOrder" :key="index" v-model="module.active" :prepend-icon="module.action" no-action color="accent" @click="changeModule(module.banner)" v-show="can(module.permissions)">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ module.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
  
            <v-list v-if="module.subsections">
              <v-list-group v-for="sections in module.sections" :key="sections.title" no-action class="pl-15"
                color="accent" :value="banner">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-show="can(sections.permissions)">{{ sections.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item v-for="subsections in sections.sections" :key="subsections.title" link class="pl-10"
                  @click="goTo(subsections.redirect)">
                  <v-list-item-title v-show="can(subsections.permissions)">{{ subsections.title }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list>
  
            <v-list-item v-else v-for="section in module.sections" :key="section.title" link  @click="goTo(section.redirect)" v-show="can(section.permissions)">
              <v-list-item-content>
                <v-list-item-title v-show="can(section.permissions)" >{{ section.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list-group>
          
          <v-divider></v-divider>
          <v-subheader class="mt-2">Configuración</v-subheader>
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-brightness-6</v-icon>
              </v-list-item-icon>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch color="accent" v-model="get_is_dark" v-on:change="updateTheme()"></v-switch>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <template v-slot:append>
          <v-list-item link @click="goTo('home')">
              <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                  <v-list-item-title>
                      <!-- {{ getConfigurationName }}  --> (Inicio)
                  </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="logout">
              <v-list-item-icon>
                  <v-icon color="error">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                  <v-list-item-title :style="{ color: styleErrorColor }">
                      Cerrar Sesión
                  </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </template>
      </v-navigation-drawer>
    </v-container>
  </template>
  
  <script>
  import default_profile_picture from "../users/images/default_profile_picture.png";
  import { can } from '../../src/helpers/permissions';
  import { mapState, mapActions, mapGetters } from "vuex";
  import _ from 'lodash';
  export default {
    data: () => ({
      banner: true,
      drawer: true,
      group: null,
      drawerConf: false,
      modules: [
        {
          title: 'Almacenes',
          action: 'mdi-warehouse',
          active: false,
          permissions: ['get-own-inventory', 'get-distribution'],
          subsections: false,
          sections: [
            {
              title: 'Distribución',
              redirect: 'almacen',
              active: false,
              permissions: ['get-distribution'],
            },
            {
              title: 'Mi inventario',
              redirect: 'productos',
              active: false,
              permissions: ['get-own-inventory'],
            },
          ],
          banner: false,
        },
        {
          title: 'Socios Comerciales',
          action: 'mdi-account-group',
          active: false,
          permissions: ['get-business-partners'],
          subsections: false,
          sections: [
            {
              title: 'Socios de Negocio',
              redirect: 'socios',
              permissions: ['get-business-partners'],
            },
          ],
          banner: false,
        },
        {
          title: 'Ventas',
          action: 'mdi-script-text-outline',
          active: false,
          permissions: ['get-sales'],
          subsections: false,
          sections: [
            {
              title: 'Tickets',
              redirect: 'tickets',
              permissions: ['get-sales'],
            },
          ],
          banner: false,
        },
      ],
    }),
    components: {},
    computed: {
      ...mapState('configuration', ['configuration']),
      ...mapState('authentication', ['userAuth','isLoggedIn']),
      ...mapGetters('user', ['getAbilitiesUser']),
      modulesOrder() {
        return _.orderBy(this.modules, 'title', 'asc');
      },
      logged() {
        return this.$route.name !== "home";
      },
      sideMenu() {
        return this.$vuetify.breakpoint.width <= 1264 && this.logged;
      },
      get_is_dark: {
        get: function () {
          return this.configuration.themeDark;
        },
        set: function (newValue) {
          return newValue;
        },
      },
      styleErrorColor() {
        return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.error : this.$vuetify.theme.themes.light.error;
      }
    },
    methods: {
      ...mapActions("configuration", ["setDarkMode"]),
      updateTheme(){
        this.setDarkMode(!this.get_is_dark);        
      },
      can(permissions) {
        return can(this.getAbilitiesUser, permissions);
      },
      logout() {
        this.$router.push({ name: "logout" });
      },
      goTo(name) {
        this.banner = false
        this.$router.push({ name: name }).catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      },
      showhere(route) {
        return this.$route.fullPath != route ? true : false;
      },
      checkImage(image) {
        if (
          image == "default_user_image.jpg" ||
          image == "default_profile_picture.png" ||
          image == null
        ) {
          return default_profile_picture;
        } else {
          return image;
        }
      },
      changeModule(banner) {
        this.banner = banner
      }
  
    },
    watch: {
      get_is_dark: function (val) {
        this.$vuetify.theme.dark = val;
      },
    },
    mounted() {
      this.$vuetify.theme.dark = this.get_is_dark;
    },
  };
  </script>
  
  <style>
  .router-link-exact-active {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #1867c0 !important;
    caret-color: #1867c0 !important;
    font-style: italic;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  
  }
  </style>
  