import _ from 'lodash';
export default function setup() {
	window.axios.interceptors.response.use(config => {
		return config;
	}, error => {
		let messages = '';
		let status = error.response.status;
		let errors = error.response.data.error;
		if(status == 400) {
			messages = errors;
		} else {
			_.forEach(errors, line => { messages += line + '\n'; });
		}
		return Promise.reject(messages);
	});
}