import api from '../../api/authentication.js';
import router from '../../routes/index.js';

const namespaced = true

const state = {
    isLoggedIn: false, 
}

const mutations = {
    SET_LOGINSTATUS(state, input_data){
        state.isLoggedIn = input_data;
    },
}

const actions = {
    login_server(context, data) {
        return new Promise((resolve) => {
            api.login_server(data).then(function (res) {
                context.dispatch('user/setUserAuth', res.data.data.user,{root: true });
                context.commit('SET_LOGINSTATUS', true);                
                router.push('/public');                
                resolve(res);
            }).catch(function () {
                context.commit('SET_LOGINSTATUS', false);
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text':'Credenciales incorrectas (FE)'}, {root: true });
                router.push('/login');
            })
        })        
   },
  
    logout(context) {
        return new Promise((resolve) => {
            context.dispatch('user/resetUserAuth',{}, {root: true });
            context.commit('SET_LOGINSTATUS', false);

            api.logout().then(function (res) {
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.data.message }, { root: true });
                router.push('/login');
                resolve(res.data);
            }).catch(function () {
                router.push('/login');
            })
        }) 
    },  
}

const getters = {

}


export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}