const namespaced = true
import api from '../../api/almacenes.js';

const state = {
    almacenes: [],
    almacen_principal : [],
    almacen_consignacion : [],
    almacen: {
        nombre : '', 
        tipo : '',
        direccion: '',
        usuario_responsable: ''
    }
}
const mutations = {
    MUTATION_SET_PRINCIPAL(state, data){
        state.almacen_principal   = data;
    },
    MUTATION_SET_CONSIGNACION(state, data){
        state.almacen_consignacion   = data;
    },
    MUTATION_SET_ALMACENES(state, data){
        state.almacenes   = data;
    },
    MUTATION_SET_ALMACEN(state, data){
         state.almacen     = data;
    }
}
const actions = {
    async getAlmacenes(context) {
        try {
            context.dispatch('progress/show', { 'loading': true, 'message': 'Obteniendo almacenes...' }, { root: true });
            const res = await api.getAlmacenes();
            
            context.commit('MUTATION_SET_PRINCIPAL', res.data.alegra_almacenes.principal);
            context.commit('MUTATION_SET_CONSIGNACION', res.data.alegra_almacenes.consignacion);

            context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
        } catch (e) {
            context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error al obtener almacenes' }, { root: true });
        }
    },
    /**
     * @description Deshabilitado en este momento ya que el almacen se crea y se asigna al momento de dar de alta algun socio de negocio.
     * @param 
     * @returns 
     */
    async postAlmacen(context) {
        try {
            context.dispatch('progress/show', { 'loading': true, 'message': 'Creando almacen...' }, { root: true });
            // PENDIENTE DE AJUSTAR...
            const res = await api.postAlmacen({'nombre' : 'almacen prueba'+Math.random(),'tipo' : 'secundario','direccion': 'direccion prueba','usuario_responsable' : 'sin definir aun' , 'socio_id' : 1});

            context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            
            return res.data;
        } catch (e) {
            context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text':  'Error al crear socio de negocio'}, { root: true });
        }
    },
}

const getters = {
    montoTotalPrincipal(state) {
        var total = 0;   
        state.almacen_principal.map(item => {
            // var iva;
            var price;
            var line;
            
            // iva = parseFloat(item.tax[0].percentage * 0.010) + 1; 
            price = parseFloat(item.price[0].price) // * parseFloat(iva);  // Se usa la posicion 0 ya que es la lista de precios de principal
            line = parseFloat(item.inventory.warehouses.availableQuantity) * parseFloat(price); // este calculo NO incluye IVA
            total = total + line;
            
        })
        return total;
    },
    
    // En este momento no hay tomar mucho en cuenta esta parte.
    // Ya que ahora el calculo no lo determinará una lista de precios sino la forma en la que se vende el articulo.
    montoTotalConsignacion(state) {   
        var total = 0;     
        state.almacen_consignacion.map(item => {            
            if(item.price.length == 2){
                var iva;
                var price;
                var line;
                
                iva = parseFloat(item.tax[0].percentage * 0.010) + 1;
                price = parseFloat(item.price[0].price) * parseFloat(iva); // Se usa la posicion 1 ya que es la lista de precios de "Consignacion"
                line = parseFloat(item.inventory.warehouses.availableQuantity) * parseFloat(price);
                total = total + line;
            }
        })

        return total;
    },
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}