import Vue from 'vue';
import Vuex from 'vuex';

/**
 *  Para mantener el estado de vuex-states de modulos especificos.
 */
import createPersistedState from  'vuex-persistedstate';

Vue.use(Vuex);

import configuration        from  './modules/configuration.js';
import notifications        from  './modules/notifications.js';
import authentication       from  './modules/authentication.js';
import abilities            from  './modules/abilities.js';
// import files                from  './modules/files.js';
// import maps                 from  './modules/maps.js';
import user                 from  './modules/user.js';
import socios               from  './modules/socios.js';
import almacenes            from  './modules/almacenes.js';
import productos            from  './modules/productos.js';
import progress             from  './modules/progress.js';
import transfers            from  './modules/transfers.js';
import ventas               from  './modules/ventas.js';
import pagos                from  './modules/pagos.js';

const dataState = createPersistedState({
  // paths: ['configuration', 'almacenes','socios', 'user']
  paths: ['user', 'authentication','configuration', 'almacenes']
  // paths: []
})

export default new Vuex.Store({
  namespace: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    configuration,
    notifications,
    authentication,
    abilities,
    // files,
    // maps,
    user,
    socios,
    almacenes,
    productos,
    progress,
    transfers,
    ventas,
    pagos
  },
  plugins: [dataState]
})