const namespaced = true
import api from '../../api/productos.js';

const state = {
    productos: [],
    
    // 'id': item.id, 
    // 'name': item.name, 
    // 'type': item.type, 
    // 'description': item.description, 
    // 'warehouse' : item.inventory.warehouses.name,
    // 'quantity' : 0,
    // 'available' : item.inventory.warehouses.availableQuantity,
    // 'unit': item.inventory.unit,
    // 'price' : item.price[0].price,
    // 'currency' : item.price[0].currency.code,
    // 'percentage' : item.tax[0].percentage,
    // 'taxName' : item.tax[0].name

    // producto: {
    // "name": "Bolsa 1",
    // "price": [
    //     {
    //         "price": "20.00"
    //     }
    // ],
    // "customFields": [],
    // "tax": "2",
    // "unit": "H87",
    // "type": "product",
    // "category": null,
    // "description": null,
    // "itemCategory": null,
    // "reference": null,
    // "accounting": {},
    // "itemType": "product",
    // "inventory": {
    //     "unitCost": "10",
    //     "unit": "H87",
    //     "warehouses": [
    //         {
    //             "id": "1",
    //             "initialQuantity": "1"
    //         }
    //     ],
    //     "negativeSale": true
    // },
    // "predial": null,
    // "taxCondition": {
    //     "value": "02"
    // },
    // "tariffFraction": null,
    // "foreignTradeUnit": null
    // }
}
const mutations = {
    MUTATION_SET_PRODUCTOS(state, data){
         state.productos   = data;
    },
    // MUTATION_SET_ALMACEN(state, data){
    //      state.almacen     = data;
    // }
}
const actions = {
    async getProductos(context) {
        try {
            const res = await api.getProductos();
            context.commit('MUTATION_SET_PRODUCTOS', res.data);
        } catch (e) {
            // context.commit('MUTATION_SET_FLAG_EVIDENCES', false);
            // context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
        }
    },
    // {nombre,tipo,direccion,usuario_responsable}
    // async postAlmacen(context) {
    //     try {
            // +Math.random()
            // const res = await api.postAlmacen({'nombre' : 'almacen prueba'+Math.random(),'tipo' : 'secundario','direccion': 'direccion prueba','usuario_responsable' : 'sin definir aun'});
            // console.log(res)
            // context.commit('MUTATION_SET_EVIDENCES', res.data);
            // context.commit('MUTATION_SET_FLAG_EVIDENCES', false);
        //     return res.data;
        // } catch (e) {
            // context.commit('MUTATION_SET_FLAG_EVIDENCES', false);
            // context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text':  'Error al crear socio de negocio'}, { root: true });
        // }
    // },
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}