const namespaced = true
import _ from 'lodash';
import api from '../../api/socios.js';
import exportFile from '../../api/export.js';
import { downloadFile } from '../../src/helpers/util.js';


const state = {
    socios: [],
    socio: {
        nombre : '',
        telefono : '',
        correo_electronico : '',
        direccion: '',
        cie : '',
        comentarios: '',
        almacenes: [{ diario : []}]
    }
}
const mutations = {
    MUTATION_SET_SOCIOS(state, data){
        state.socios = data;
    },
    MUTATION_SET_SOCIO(state, data){
        state.socio = data;
    },
    MUTATION_SET_SOCIO_RESET(state){
        state.socio = {nombre : '', telefono : '', correo_electronico : '', direccion: '', cie : '', comentarios: ''}
    }
}
const actions = {    
    async getSocios(context) {
        try {
            context.dispatch('progress/show', { 'loading': true, 'message': 'Obteniendo socios...' }, { root: true });
 
            const res = await api.getSocios();
            await context.commit('MUTATION_SET_SOCIOS', res.data.socios);

            await context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            await context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
        } catch (e) {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error al recuperar información' }, { root: true });
        }
    },
    async getSocio(context) {
        try {
            context.dispatch('progress/show', { 'loading': true, 'message': 'Obteniendo información del socio...' }, { root: true });
 
            const res = await api.getSocio();
            await context.dispatch('socios/selectSocio', res.data.socio, { root: true })

            await context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            await context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
        } catch (e) {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': 'Error al recuperar información' }, { root: true });
        }
    },
    async postSocio(context) {
        try {
            context.dispatch('progress/show', { 'loading': true, 'message': 'Creando socio...' }, { root: true });

            const res = await api.postSocio(context.state.socio);

            context.commit('MUTATION_SET_SOCIO_RESET');            
            context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });

            context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
            context.dispatch('socios/getSocios',{},{ root: true });
        } catch (e) {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text':  'Error al crear socio de negocio'}, { root: true });
        }
    },
    async selectSocio(context,data) {
        try {
            await context.commit('MUTATION_SET_SOCIO', data);
        } catch (e) {
            // context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text':  'Error al crear socio de negocio'}, { root: true });
        }
    },
    downloadFile(context,data) {
        return new Promise((resolve) => {
            exportFile.getFile(data).then(function (res) {
                downloadFile(res.data, 'test.xlsx');
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING_DOWNLOAD', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    }
}

const getters = {
    getStock (state) {
        try {
            const diario = state.socio.almacenes[0].diario;
            const agrupado = _.groupBy(diario, item => [item.producto_id, item.almacen_id]);

            const productosConStock = _.map(agrupado, items => {
                const stock = _.sumBy(items, 'producto_cantidad');
                const primerItem = _.head(items);
                return {
                    ...primerItem,
                    producto_cantidad: stock 
                };
            });
            return productosConStock;            
        } catch (error) {
            return [];
        }        
    }
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}