import _ from 'lodash';

export function can(abilitiesUserlog, abilitiesEvaluate) {
    let can = false;
	if(_.isString(abilitiesEvaluate)){
		_.forEach(abilitiesUserlog, ability => {
			if(ability.name == abilitiesEvaluate || ability.name == '*') can = true;
		})
	}
	if(_.isArray(abilitiesEvaluate)){
		_.forEach(abilitiesUserlog, ability => {
			if(ability.name == '*') can = true;
			_.forEach(abilitiesEvaluate, evaluate => {
				if(evaluate == ability.name) can = true;
			})
		});
	}
	return can;
}