import Vue from 'vue';  
import Router from 'vue-router'
// import store from './../store/modules/authentication.js';

Vue.use(Router);

const routerOptions = [
  // rutas compartidas
  {
    path: '/',
    name: 'public',
  },
  {
    path: '/login',
    name: 'login',
  },
  {
    path: '/logout',
    name: 'logout',
  },
  // NO IMPLEMENTADAS
  {
    path: '/register',
    name: 'register',
  },
  // RUTAS EXCLUSIVAS ADMIN
  {
    path: '/socios',
    name: 'socios',
  },
  {
    path: '/socios/almacen',
    name: 'socios/almacen',
  },
  {
    path: '/almacen',
    name: 'almacen',
  },
  {
    path: '/almacen/principal',
    name: 'almacen/principal',
  },
  // RUTAS SOCIO
  {
    path: '/productos',   // Lista de productos del socio de negocio.
    name: 'productos',
  },
  {
    path: '/almacen/consignacion',
    name: 'almacen/consignacion',
  },
  {
    path: '/socio/ventas',
    name: 'ventas',
  },
  {
    path: '/tickets',
    name: 'tickets',
  },
]

// Rutas
const routes = routerOptions.map(r => {
  return {
    ...r,
    component: () => import(`../components/${r.name}/index-component.vue`)
  }
})

const router = new Router({
  // mode: 'history',
  base: __dirname,
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'login' && !store.getters['authentication/isLoggedIn']) {
//     // Redirige al componente de login si no está autenticado
//     next('/login');
//   } else {
//     // Continúa con la navegación si está autenticado
//     next();
//   }
// });

export default router