const namespaced = true

import api from '../../api/ventas.js';
import router from '../../routes/index.js';

const state = {
    venta: {        
        date: "",
        dueDate: "",
        priceList: -1,
        seller: -1, 
        warehouse: -1,
        numberTemplate: {
            "id": 2,
            "text": null
        },
        items: [],
        paymentType: "PUE",
        paymentMethod: "cash",
        regime: "NO_REGIME",
        termsConditions: "",
        anotation: "",
        advances: [],
        datetime: '',
        status: "open",
        stamp: {
            "generateStamp": false,
            "version": "4.0",
            "timezone": "America/Mexico_City"
        }
    },
    ventas_pendientes : [],
    tickets : [],
}
const mutations = {
    MUTATION_SET_ITEM_LINE(state, data){
        state.venta.items.push(data);
    },
    MUTATION_SET_VENTA_RESET(state){
        state.venta = {        
            date: "",
            dueDate: "",
            priceList: -1,
            seller: -1, 
            warehouse: -1,
            numberTemplate: {
                "id": 2,
                "text": null
            },
            items: [],
            paymentType: "PUE",
            paymentMethod: "cash",
            regime: "NO_REGIME",
            termsConditions: "",
            anotation: "",
            advances: [],
            datetime: '',
            status: "open",
            stamp: {
                "generateStamp": false,
                "version": "4.0",
                "timezone": "America/Mexico_City"
            }
        }
    },
    MUTATION_REMOVE_ITEM(state,item){
        state.venta.items.splice(state.venta.items.indexOf(item),1);
    },
    MUTATION_SET_VENTAS_PENDIENTES(state, data){
        state.ventas_pendientes = data;
    },
    MUTATION_SET_TICKETS(state, data){
        state.tickets = data;
    },
    MUTATION_SET_PRICELIST(state, data){
        state.venta.priceList = data;
    },
    MUTATION_SET_USER_ID(state, id){
        state.venta.seller = id;
    }
}
const actions = {
    addLine(context,item) {
        var isAction = true;

        for(var i = 0; i < context.state.venta.items.length; i++){

            if(context.state.venta.items[i].id === item.producto_id ) {
                isAction = false;
            }

        }
        if(isAction){
            context.commit('MUTATION_SET_ITEM_LINE', {
                'id': item.producto_id, 
                'name' : item.producto_nombre,
                'price' : item.producto_precio_venta, // Este price se toma de la referencia de la tabla, se utiliza para los registros locales
                'currency' : item.producto_moneda,
                'quantity' : 0,
                'reference' : "",
                'description': "", 
                'predial': "",
                'discount': 0,
                'unit': item.producto_unidad,
                'taxValue': item.producto_impuesto,
                'tax': [ { "id": 2 } ],
                'MaxLimit' : item.producto_cantidad
            });
        }
    },
    reset(context){
        context.commit('MUTATION_SET_VENTA_RESET');
    },
    async create(context, {options}){
        try {           
            await context.dispatch('progress/show', { 'loading': true, 'message': 'Creando venta con ticket...' }, { root: true });            
            
            await context.commit('MUTATION_SET_PRICELIST', options.priceList);           
            const res = await api.create(context.state.venta, options);

            await context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            await context.commit('MUTATION_SET_VENTA_RESET');

            router.push('/almacen');
            return res.data;

        } catch (e) {
            context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text':  'Error al registrar la transferencia (FE)'}, { root: true });
        }
    },
    async getVentasPendientes(context){
        try {
            context.dispatch('progress/show', { 'loading': true, 'message': 'Obteniendo tickets de venta...' }, { root: true });
            
            const res = await api.getVentasPendientes();

            await context.commit('MUTATION_SET_VENTAS_PENDIENTES', res.data.tickets_pendientes)

            await context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            
            return res.data;
        } catch (e) {
            context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text':  'Error al registrar la transferencia (FE)'}, { root: true });
        }
    },
    async removeItem(context,item){
        await context.commit("MUTATION_REMOVE_ITEM", item);
    },
    async getTickets(context, {status}){
        try {
            await context.dispatch('progress/show', { 'loading': true, 'message': 'Obteniendo tickets de venta...' }, { root: true });
            
            const res = await api.getTickets(status);

            await context.commit('MUTATION_SET_TICKETS', res.data.tickets)

            await context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            
            return res.data;

        } catch (e) {
            context.dispatch('progress/show', { 'loading': false, 'message': '' }, { root: true });
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text':  'Error al consultar tickets (FE)'}, { root: true });
        }
    },
    setUserId(context) {
        context.commit('MUTATION_SET_USER_ID', context.rootState.socios.socio.id); 
    },
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}